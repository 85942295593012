import Vue from 'vue'

Vue.prototype.$uploadImage = function(image) {
    return new Promise((resolve, reject) => {
        const { canvas } = image.getResult()
        canvas.toBlob(file => {
            file = new File([file], `name`)
            let data = new FormData()
            data.append(`file`, file, `temp.jpeg`)

            this.$httpInt.post(`/v2/app/images`, data, { headers: { 'Content-Type': `multipart/form-data` } } )
                .then(res => resolve(res.data))
                .catch(err => reject(err))
        }, `image/jpeg`)
    })
}

Vue.prototype.$uploadGif = function(file) {
    return new Promise((resolve, reject) => {
        let form_data = new FormData();
        form_data.append('file', file);

        this.$httpInt.post(`/v2/app/images`, form_data, { headers: { 'Content-Type': `multipart/form-data` } } )
            .then(res => resolve(res.data))
            .catch(err => reject(err))
    })
}


