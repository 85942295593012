import Vue from 'vue'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { extend, localize } from "vee-validate"
import { required, email, min, max, confirmed, numeric } from 'vee-validate/dist/rules'
import en from 'vee-validate/dist/locale/en.json'
/*
	// Detta ger problem n�r man laddar in i efterhand via json, alla ej utf-8 blir fr�getecken. Beh�ver decodas efter man tagit emot filen via lazy loading.
	import(`vee-validate/dist/locale/sv.json`).then(locale => {
		localize(locale.code, locale)
	})
*/

localize('en', en)
extend('min', min)
extend('required', required)
extend('email', email)
extend('numeric', numeric)
extend('max', max)
extend('confirmed', confirmed)

Vue.component('ValidationProvider', ValidationProvider)
Vue.component('ValidationObserver', ValidationObserver)