function reorderIndex(items) {
	let index = 0
	items.map(o => {
		if (o.index !== null) o.index = index++
	})
	return items;
}

export default {
	state: {
		sections: []
	},
	getters: {
		getExploreSection: store => (sectionIndex) => {
			return store.sections[sectionIndex]
		},
		getExploreSections: store => () => {
			return store.sections
		},
		getExploreSectionCard: store => (sectionIndex, cardIndex) => {
			return store.sections[sectionIndex].cards[cardIndex]
		},
	},
	mutations: {
		SET_SECTIONS(state, payload) {
			state.sections = payload || []
		},
		ADD_SECTION(state, payload) {
			state.sections.map(o => o.index >= payload.index && o.index++)
			state.sections.splice(payload.index, 0, payload);
		},
		DELETE_SECTION(state, payload) {
			const items = state.sections
			state.sections = items.slice(0, payload.sectionIndex).concat(items.slice(payload.sectionIndex + 1, items.length))
		},
		SAVE_SECTION(state, payload) {
			let items = state.sections
			items.splice(payload.sectionIndex, 1, payload.section);
			state.sections = items
		},
		REORDER_SECTIONS(state, payload) {
			state.sections = payload.sections
		},
		ADD_CARD(state, payload) {
			let items = state.sections[payload.sectionIndex].cards
			items.push(payload.card)
			state.sections[payload.sectionIndex].cards = reorderIndex(items)
		},
		DELETE_CARD(state, payload) {
			let items = state.sections[payload.sectionIndex].cards
			items = items.slice(0, payload.cardIndex).concat(items.slice(payload.cardIndex + 1, items.length))
			state.sections[payload.sectionIndex].cards = reorderIndex(items)
		},
		SAVE_CARD(state, payload) {
			payload.card.index = payload.card.randomize ? null : 0

			let items = state.sections[payload.sectionIndex].cards
			items.splice(payload.cardIndex, 1, payload.card);
			state.sections[payload.sectionIndex].cards = reorderIndex(items)
		},
		REORDER_CARDS(state, payload) {
			state.sections[payload.sectionIndex].cards = reorderIndex(payload.cards)
		},
	},
	actions: {
		setSections: ({commit}, payload) => {
			commit('SET_SECTIONS', payload)
		},
		addSection: ({commit}, payload) => {
			commit('ADD_SECTION', payload)
		},
		deleteSection: ({commit}, payload) => {
			commit('DELETE_SECTION', payload)
		},
		saveSection: ({commit}, payload) => {
			commit('SAVE_SECTION', payload)
		},
		reorderSections: ({commit}, payload) => {
			commit('REORDER_SECTIONS', payload)
		},
		addCard: ({commit}, payload) => {
			commit('ADD_CARD', payload)
		},
		deleteCard: ({commit}, payload) => {
			commit('DELETE_CARD', payload)
		},
		saveCard: ({commit}, payload) => {
			commit('SAVE_CARD', payload)
		},
		reorderCards: ({commit}, payload) => {
			commit('REORDER_CARDS', payload)
		},
	}
}