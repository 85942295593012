/**
 * Define all of your application routes here
 * for more information on routes, see the
 * official documentation https://router.vuejs.org/en/
 */
export default [
  {
    path: '/',
    view: 'Dashboard',
    name: 'Dashboard',
  },
  {
    path: '/feed/explore',
    view: 'FeedExplore',
    name: 'Explore',
  },
  {
    path: '/feed/v2',
    view: 'FeedV2',
    name: 'Feed V2',
  },
  {
    path: '/community/overview',
    view: 'CommunityOverview',
    name: 'Overview',
  },
  {
    path: '/community/profiles',
    view: 'CommunityProfiles',
    name: 'Profiles',
  },
  {
    path: '/community/posts',
    view: 'CommunityPosts',
    name: 'Posts',
  },
  {
    path: '/community/post/:id',
    view: 'CommunityPost',
    name: 'Post',
  },
  {
    path: '/community/profile/:id',
    view: 'CommunityProfile',
    name: 'Profile',
  },
  {
    path: '/community/categories',
    view: 'CommunityCategories',
    name: 'Categories',
  },
  {
    path: '/community/claims',
    view: 'CommunityClaims',
    name: 'Claims',
  },
  {
    path: '/community/comments',
    view: 'CommunityComments',
    name: 'Comments',
  },
  {
    path: '/community/images',
    view: 'CommunityImages',
    name: 'Images',
  },
  {
    path: '/messages/direct-messages',
    view: 'MessagesDirectMessages',
    name: 'Direct Messages',
  },
  {
    path: '/messages/rules-messages',
    view: 'MessagesRulesMessages',
    name: 'Rules Messages',
  },
  {
    path: '/messages/group-messages',
    view: 'MessagesGroupMessages',
    name: 'Group Messages',
  },
  {
    path: '/messages/rules',
    view: 'MessagesRules',
    name: 'Rules Settings',
  },
  {
    path: '/community/abuse',
    view: 'CommunityAbuse',
    name: 'Abuse',
  },
  {
    path: '/community/suggestions',
    view: 'CommunitySuggestions',
    name: 'Suggestions',
  },
  {
    path: '/partners/activity',
    view: 'PartnersActivity',
    name: 'Activity',
  },
  {
    path: '/profiles-of-interest/profiles',
    view: 'ProfilesOfInterestProfiles',
    name: 'Profiles of Interest',
  },
  {
    path: '/profiles-of-interest/active-public-non-partners',
    view: 'ProfilesOfInterestActivePublicNonPartners',
    name: 'Active Non Partners',
  },
  {
    path: '/news/posts',
    view: 'NewsPosts',
    name: 'News Posts',
  },
  {
    path: '/news/rss-sources',
    view: 'NewsRssSources',
    name: 'Sources',
  },
  {
    path: '/news/filter',
    view: 'NewsFilter',
    name: 'Filter',
  },
  {
    path: '/statistics/social-media',
    view: 'StatisticsSocialMedia',
  },
  {
    path: '/statistics/kpi',
    view: 'StatisticsKpi',
  },
  {
    path: '/statistics/detailed-metrics',
    view: 'StatisticsDetailedMetrics',
  },
  {
    path: '/statistics/utm-source',
    view: 'StatisticsUtmSource',
  },
  {
    path: '/statistics/flags',
    view: 'StatisticsFlags',
  },
  {
    path: '/statistics/planted-trees',
    view: 'StatisticsPlantedTrees',
  },
  {
    path: '/system/request-logs',
    view: 'SystemRequestLogs',
  },
  {
    path: '/system/internal-logs',
    view: 'SystemInternalLogs',
  },
  {
    path: '/system/admins',
    view: 'SystemAdmins',
  },
  {
    path: '/sendinblue/templates',
    view: 'SendinblueTemplates',
    name: 'SendInBlue Templates',
  },
  {
    path: "/login",
    view: "Login",
  },
  {
    path: "/login/code",
    view: "LoginCode",
  },
]
