import vuetify from '@/plugins/vuetify'

export default {
	state: {
		name: null,
		role: null,
		settings: {},
	},
	getters: {
		getAdmin: store => () => {
			return store
		},
		adminAuthenticated: store => () => {
			return store.name !== null
		},
		getAdminRole: store => () => {
			return store.role
		},
		isDarkMode: store => () => {
			return store.settings.dark_mode
		},
	},
	mutations: {
		UPDATE_ADMIN(state, payload) {
			state.name = payload.name ?? null
			state.role = payload.role ?? null
			state.settings = payload.settings ?? {}
			vuetify.framework.theme.dark = state.settings.dark_mode ?? false
		},
		LOGOUT_ADMIN(state) {
			state.name = null
			state.role = null
			state.settings = {}
		},
		UPDATE_THEME(state, payload) {
			state.settings.dark_mode = payload
			vuetify.framework.theme.dark = payload
		},
	},
	actions: {
		setAdmin: ({commit}, payload) => {
			commit('UPDATE_ADMIN', payload)
		},
		logoutAdmin: ({commit}) => {
			commit('LOGOUT_ADMIN')
		},
		setTheme: ({commit}, payload) => {
			commit('UPDATE_THEME', payload)
		},
	}
}