<template>
	<v-btn
		v-bind="$attrs"
		v-on="$listeners"
		elevation="0"
		data-lpignore="true"
		:color="$attrs.type === `submit` && `primary` || $attrs.color"
		:class="`rounded-xs ` + ($attrs.type === `submit` && `px-8`)"
	>
		<slot></slot>
	</v-btn>
</template>

<script>
export default {
	name: `Button`,
}
</script>