<template>
	<v-snackbar
		v-model="show"
		:timeout="options.timeout"
		:color="options.color"
	>
		<p v-if="options.title" v-html="options.title" class="font-weight-bold"></p>
		<span v-html="message"></span>
		<template v-slot:action="{ attrs }">
			<Button @click="show = false" icon v-bind="attrs">
				<v-icon class="mr-1">mdi-close</v-icon>
			</Button>
		</template>
	</v-snackbar>
</template>

<script>
export default {
	data: () => ({
		show: false,
		message: ``,
		options: {
			color: `black`,
			timeout: 2000,
			title: null,
		},
	}),
	name: `MaterialSnackbar`,
	methods: {
		open(message, options) {
			this.options.title = null
			if (!options || !options.color) this.options.color = `black`
			if (!options || !options.timeout) this.options.timeout = 2000
			this.show = true
			this.message = message
			this.options = Object.assign(this.options, options)
		},
	}
}
</script>