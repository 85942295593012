<template>
	<v-row>
		<v-col class="text-center">
			<div v-if="showText">Loading...</div>
			<v-progress-circular
				class="mt-4"
				:size="70"
				color="primary"
				indeterminate
			></v-progress-circular>
		</v-col>
	</v-row>
</template>

<script>
export default {
	name: `CoreLoader`,
	props: {
		loading: {
			type: Boolean,
			default: false,
		},
		showText: {
			type: Boolean,
			default: true,
		},
	},
}
</script>