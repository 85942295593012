import VueCookies from "vue-cookies";

if (location.protocol != 'https:' && location.hostname != 'localhost' && location.hostname != 'ambassadors.local-wedonthavetime.org')
	location.href = 'https:' + window.location.href.substring(window.location.protocol.length)
import Vue from 'vue'
import App from '@/App.vue'
import router from '@/router'
import store from '@/store/store'
import '@/plugins'
import vuetify from '@/plugins/vuetify'
import '@/plugins/elements.js'
import '@/plugins/vee-validate'
import Loader from '@/components/core/Loader'
import dayjs from 'dayjs'
import { sync } from 'vuex-router-sync'
import {mapActions, mapGetters} from "vuex";
import relativeTime from "dayjs/plugin/relativeTime";

sync(store, router)

dayjs.extend(relativeTime)
Vue.prototype.dayjs = dayjs
Vue.config.productionTip = false

Vue.component(Loader)

Vue.filter('capitalize', function (value) {
	if (!value) return ``
	value = value.toString()
	return value.charAt(0).toUpperCase() + value.slice(1)
})

Vue.filter('ellipsis', function (value, limit) {
	return `${value.toString().slice(0, limit)}${value.length > limit - 3 ? `...` : ``}`
})

new Vue({
	router,
	store,
	vuetify,
	render: h => h(App),
	computed: {
		...mapGetters([`getAdmin`, `adminAuthenticated`]),
	},
	mounted() {
		if (!this.adminAuthenticated()) {
			if (VueCookies.get(`token`)) {
				this.$httpInt.get(`/v2/auth/token`)
					.then(res => {
						this.$vuetify.theme.dark = res.data.result.settings.dark_mode
						this.setAdmin(res.data.result)
						this.$httpInt.get(`/v2/system/config`)
							.then(res => Vue.prototype.$appUrl = res.data.result.app.url)
					})
					.catch(() => this.$router.push({name: `Login`}))
			} else {
				this.$router.push({name: `Login`})
			}
		}
	},
	methods: {
		logout() {
			this.logoutAdmin()
		},
		...mapActions([`setAdmin`, `logoutAdmin`]),
	},
}).$mount('#app')
