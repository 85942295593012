<template>
	<v-dialog
		v-model="dialog"
		:max-width="options.width"
		:style="{ zIndex: options.zIndex }"
		@keydown.esc="cancel"
		@click:outside="cancel"
	>
		<v-card>
			<v-toolbar dense elevation="0">
				<v-toolbar-title>{{ title }}</v-toolbar-title>
			</v-toolbar>

			<v-divider />

			<v-card-text v-show="!!message" class="pa-4">{{ message }}</v-card-text>
			<v-card-actions class="pt-0">
				<v-spacer />
				<template v-if="options.type === 'confirm'">
					<Button @click.native="cancel">Cancel</Button>
					<Button :color="options.color" @click.native="agree">Yes</Button>
				</template>
				<template v-if="options.type === 'info'">
					<Button :color="options.color" @click.native="agree">Ok</Button>
				</template>

			</v-card-actions>
		</v-card>
	</v-dialog>
</template>

<script>
/**
 * Vuetify Confirm Dialog component
 *
 * Insert component where you want to use it:
 * <confirm ref="confirm"></confirm>
 *
 * Call it:
 * this.$refs.confirm.open('Delete', 'Are you sure?', { color: 'red' }).then((confirm) => {})
 * Or use await:
 * if (await this.$refs.confirm.open('Delete', 'Are you sure?', { color: 'red' })) {
 *   // yes
 * }
 * else {
 *   // cancel
 * }
 *
 * Alternatively you can place it in main App component and access it globally via this.$root.$confirm
 * <template>
 *   <v-app>
 *     ...
 *     <confirm ref="confirm"></confirm>
 *   </v-app>
 * </template>
 *
 * mounted() {
 *   this.$root.$confirm = this.$refs.confirm.open
 * }
 */
export default {
	data: () => ({
		dialog: false,
		resolve: null,
		reject: null,
		message: null,
		title: null,
		options: {
			color: `primary`,
			width: 290,
			zIndex: 200,
			type: `confirm`,
		}
	}),
	name: `MaterialConfirm`,
	methods: {
		open(title, message, options) {
			if (!options || !options.type) this.options.type = `confirm`
			this.dialog = true
			this.title = title
			this.message = message
			this.options = Object.assign(this.options, options)

			return new Promise((resolve, reject) => {
				this.resolve = resolve
				this.reject = reject
			})
		},
		agree() {
			this.resolve()
			this.dialog = false
		},
		cancel() {
			this.reject()
			this.dialog = false
		}
	}
}
</script>