import Vuex from 'vuex'
import Vue from 'vue'
import admin from './modules/admin'
import explore from './modules/explore'
import drawer from './modules/drawer'
import category from './modules/category'

Vue.use(Vuex)

export default new Vuex.Store({
	strict: true,
	modules: {
		admin,
		explore,
		drawer,
		category,
	},
})