export default {
	state: {
		categories: []
	},
	getters: {
		getCategories: store => () => {
			return store.categories
		},
	},
	mutations: {
		UPDATE_CATEGORIES(state, payload) {
			state.categories = payload || []
		}
	},
	actions: {
		setCategories: ({commit}, payload) => {
			commit('UPDATE_CATEGORIES', payload)
		},
	}
}