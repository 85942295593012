<template>
	<div style="position: relative;">
		<v-list-item-avatar
			v-bind="$attrs"
			v-on="$listeners"
			data-lpignore="true"
			class="ma-0"
		>
			<img v-if="isUrl(source)" :src="source" />
			<svg-icon v-else-if="isIcon(source)" style="margin:8px;" :icon="source" />
		</v-list-item-avatar>
		<div v-if="subSource" class="overlap-avatar pa-1 rounded-circle" :style="`background-color: ${this.$vuetify.theme.dark ? `#1E1E1E`:`white`};`">
			<svg-icon v-if="isIcon(subSource)" :icon="subSource === `bomb` && `warning` || subSource" />
		</div>
	</div>
</template>

<style lang="scss">
.overlap-avatar {
	position: absolute;
	bottom: -4px;
	right: -4px;
	> svg {
		display: block;
		margin: auto;
	}
}
</style>

<script>
export default {
	methods: {
		isUrl(url) {
			return url && url.startsWith(`http`)
		},
		isIcon(icon) {
			return icon && !icon.startsWith(`http`)
		}
	},
	name: `Avatar`,
	props: {
		source: {
			type: String,
			default: ``,
		},
		subSource: {
			type: String,
			default: ``,
		},
	},
}
</script>