<template>
	<v-app>
		<svg-sprite />
		<template >
			<core-app-bar v-if="adminAuthenticated()" />
			<core-drawer v-if="adminAuthenticated()" />
			<core-view />
		</template>

		<confirm ref="confirm" />
		<confirm2 ref="confirm2" />
		<snackbar ref="snackbar" />
	</v-app>
</template>

<style lang="scss">
@import 'src/assets/css/main.scss';
</style>

<script>
import axios from 'axios'
import Confirm from '@/components/material/Confirm'
import Confirm2 from '@/components/dialogs/Confirm'
import Snackbar from '@/components/material/Snackbar'
import SvgSprite from "@/components/core/SvgSprite"
import { mapActions } from 'vuex'
import { mapGetters } from 'vuex'

axios.defaults.headers.common['Content-Type'] = 'application/json'

export const globalMixIn = {
	computed: {
		...mapGetters([`getAdmin`, `adminAuthenticated`]),
	},
	data: () => ({
		axios: axios,
	}),
	methods: {
		getApi (url, config) {
			return new Promise((resolve, reject) => {
				axios.get(url, config )
					.then(res => resolve(res.data))
					.catch(err => reject(err))
			})
		},
		openInBrowser(url) {
			window.open(url, "_blank")
		},
		localeInteger(number) {
			if (typeof number === 'string' || number instanceof String) number = parseInt(number)
			return number && number.toLocaleString('sv-SE', {maximumFractionDigits:2}) || ``
		},
		copyToken(at_name) {
			return new Promise((resolve, reject) => {
				this.$httpInt.get(`/v2/app/legacy/admin/users/get-token`, { params: { at_name } })
					.then(res => resolve(res.data.result.token))
					.catch(err => reject(err))
			})
		},
	},
}

export default {
	components: {
		SvgSprite,
		CoreDrawer: () => import('@/components/core/Drawer'),
		CoreFooter: () => import('@/components/core/Footer'), // eslint-disable-line
		CoreAppBar: () => import('@/components/core/AppBar'), // eslint-disable-line
		CoreView: () => import('@/components/core/View'),
		Confirm,
		Confirm2,
		Snackbar,
	},
	computed: {
		...mapGetters([`getAdmin`]),
	},
	created() {
		this.$httpInt.interceptors.response.use(undefined, err => {
			if (err.response.status === 403) {
				this.$root.$snackbar(err.response.data.message || `Permission denied`, { color: 'error' })
				return Promise.reject(err)
			} else if (err.response.status === 422) {
				let errors = []
				Object.values(err.response.data.errors).forEach(o => o.forEach(e => errors.push(`• ${e}`)))
				this.$root.$snackbar(errors.join(`<br>`), { color: 'error', timeout: 0, title: err.response.data.message })
				return Promise.reject(err)
			} else if (err.response.status >= 400) {
				this.$root.$snackbar(err.response.data.message || `Unknown error`, { color: 'error' })
				return Promise.reject(err)
			}
		})
	},
	mounted() {
		this.$root.$confirm = this.$refs.confirm.open
		this.$root.$confirm2 = this.$refs.confirm2.open
		this.$root.$snackbar = this.$refs.snackbar.open
		this.syncCategories()
	},
	methods: {
		...mapActions([`setCategories`]),
		syncCategories() {
			this.$httpInt.get(`/v2/app/categories/`)
			.then(res => this.setCategories(res.data.result))
		},
		logout() {
			this.logoutAdmin()
		},
		...mapActions([`setAdmin`, `logoutAdmin`]),
	},
	mixins: [globalMixIn],
}
</script>
