import Vue from 'vue'
import Vuetify from 'vuetify'
import VBtn from 'vuetify/lib/components/VBtn'
import VListGroup from 'vuetify/lib/components/VList/VListGroup'
import VTab from 'vuetify/lib/components/VTabs/VTab'

Vue.use(Vuetify)

VBtn.options.props.ripple.default = false
VListGroup.options.props.ripple.default = false
VTab.options.props.ripple.default = false

export default new Vuetify({
  theme: {
    dark: false,
    themes: {
      light: {
        secondary: '#4caf50',
        tertiary: '#495057',
        primary: '#19cd9b',
        accent: '#82B1FF',
        error: '#f55a4e',
        info: '#00d3ee',
        success: '#5cb860',
        love: '#19CD9B',
        idea: '#19AAD1',
        warning: '#FFBB00',
        pledge: '#DE2251',
      },
      dark: {
        secondary: '#4caf50',
        tertiary: '#495057',
        primary: '#19cd9b',
        accent: '#82B1FF',
        error: '#f55a4e',
        info: '#00d3ee',
        success: '#5cb860',
        love: '#19CD9B',
        idea: '#19AAD1',
        warning: '#FFBB00',
        pledge: '#DE2251',
      }
    }
  },
  icons: {
    iconfont: 'mdi'
  }
})
