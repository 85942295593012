<template>
	<v-textarea
		v-model="innerValue"
		v-bind="$attrs"
		v-on="$listeners"
		data-lpignore="true"
		hide-details="auto"
		placeholder=" "
		auto-grow
	></v-textarea>
</template>

<script>
export default {
	name: `Textarea`,
	props: {
		value: {
			type: null
		}
	},
	data: () => ({
		innerValue: ""
	}),
	watch: {
		// Handles internal model changes.
		innerValue(newVal) {
			this.$emit("input", newVal)
		},
		// Handles external model changes.
		value(newVal) {
			this.innerValue = newVal
		}
	},
	created() {
		if (this.value) {
			this.innerValue = this.value
		}
	},
}
</script>