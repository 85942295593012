export default {
	state: {
		drawer: true
	},
	getters: {
		getDrawer: store => () => {
			return store.drawer
		},
	},
	mutations: {
		UPDATE_DRAWER(state, payload) {
			state.drawer = payload || false
		}
	},
	actions: {
		setDrawer: ({commit}, payload) => {
			commit('UPDATE_DRAWER', payload)
		},
	}
}