<template>
	<svg class="icon" :class="{ 'icon-spin': spin }" :style="fillColor()">
		<use :href="`#${icon}`" />
	</svg>
</template>

<script>
export default {
	name: `SvgIcon`,
	props: {
		icon: {
			type: String,
			required: true,
		},
		color: {
			type: String,
			default: ``,
		},
		spin: {
			type: Boolean,
			default: false,
		},
	},
	methods: {
		fillColor() {
			if (this.color === `white`) return `fill:#ffffff`
			if (this.color === `green`) return `fill:green`
			if (this.color === `red`) return `fill:red`
			if (this.icon === `love`) return `fill:#19CD9B`
			if (this.icon === `idea`) return `fill:#19AAD1`
			if (this.icon === `pledge`) return `fill:#DE2251`
			if (this.icon === `warning`) return `fill:#FFBB00`
			return ``
		}
	},
}
</script>

<style>
svg.icon {
	fill: currentColor;
	height: 1em;
	margin-bottom: 0.125em;
	vertical-align: middle;
	width: 1em;
}
svg.icon-spin {
	animation: icon-spin 2s infinite linear;
}
@keyframes icon-spin {
	from {
		transform: rotate(0deg);
	}
	to {
		transform: rotate(359deg);
	}
}
</style>