import Vue from 'vue'

Vue.prototype.$authorized = function(allowedRoles) {
    if (this.$store.getters.getAdminRole() === `admin`) return true
    if (!allowedRoles) return false
    return allowedRoles.includes(this.$store.getters.getAdminRole())
}

Vue.prototype.$superAuthorized = function() {
    if (this.$store.getters.getAdminRole() === `admin` && [`Ingmar`, `Mario`].includes(this.$store.getters.getAdmin().name)) return true
    return false
}

Vue.prototype.$authenticated = function() {
    return this.$store.getters.adminAuthenticated()
}

Vue.prototype.$debounce = function(fn, timer = 300) {
    clearTimeout(this._timerId)
    this._timerId = setTimeout(() => { fn() }, timer)
}

Vue.prototype.$confirm = function(title, message) {
    return this.$root.$confirm2(title, message)
}

Vue.prototype.$paginationParams = function(_this, additional) {
    let data = {
        page: _this.options.page,
        per_page: _this.options.itemsPerPage,
        q: _this.search,
        sort_by: _this.options.sortBy[0],
        sort_by_desc: _this.options.sortDesc[0],
    }

    if (additional) {
        data = { ...additional, ...data }
    }

    return data
}